<template>
    <div>
        <a-form-model :model="task">
            <div class="row g-gs">
                <div class="col-lg-6">
                    <a-form-model-item label="Priority">
                        <a-select class="a-select" v-model="task.priorityId">
                            <a-select-option v-for="p in taskPriorities" v-bind:key="p.id">
                                {{ p.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Due Date">
                        <a-date-picker class="a-dp" v-model="task.dueDate" show-time format="DD-MM-YYYY HH:mm" placeholder="Due Date" />
                    </a-form-model-item>
                </div>
                <div class="col-lg-6">
                    <a-form-model-item label="Status">
                        <a-select class="a-select" v-model="task.statusId">
                            <a-select-option v-for="s in taskStatus" v-bind:key="s.id">
                                {{ s.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Type">
                        <a-select class="a-select" v-model="task.typeId">
                            <a-select-option v-for="t in taskTypes" v-bind:key="t.id">
                                {{ t.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
            </div>
            <div class="row g-gs">
                <div class="col-lg-12">
                    <h6>Task Information</h6>
                </div>
                <div class="col-lg-6">
                    <a-form-model-item label="Subject">
                        <a-input v-model="task.subject" />
                    </a-form-model-item>
                    <a-form-model-item label="Reminder Date">
                        <a-date-picker class="a-dp" v-model="task.reminderDate" show-time format="DD-MM-YYYY HH:mm" placeholder="Reminder Date" />
                    </a-form-model-item>
                    <a-form-item label="Created By">
                        <a-input disabled v-model="task.createdByUserId" style="background-color:white; color:black;"></a-input>
                    </a-form-item>
                    <a-form-item label="Completed At">
                        <a-input disabled v-model="task.completedDate" style="background-color:white; color:black;"></a-input>
                    </a-form-item>
                </div>
                <div class="col-lg-6">
                    <a-form-model-item label="Account">
                        <a-select class="a-select" v-model="task.accountId">
                            <a-select-option v-for="a in accounts" v-bind:key="a.id">
                                {{ a.accountName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Contact">
                        <a-select class="a-select" v-model="task.contactId" show-search placeholder="Select a Contact" option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                            <a-select-option v-for="contact in contacts" v-bind:key="contact.id">
                                {{ contact.firstName + ' ' + contact.lastName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Sales Order">
                        <a-select class="a-select" v-model="task.salesOrderId" show-search placeholder="Select a Sales Order" option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                            <a-select-option v-for="contact in contacts" v-bind:key="contact.id">
                                {{ contact.firstName + ' ' + contact.lastName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-item label="Modified By">
                        <a-input disabled v-model="task.updatedBy" style="background-color:white; color:black;"></a-input>
                    </a-form-item>
                </div>
            </div>
            <a-form-model-item>
                <a-button class="btn-primary" @click="saveTask">
                    Save
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <div class="row g-gs">
            <div class="col-lg-12">
                <h6>Notes</h6>
                <notes/>
            </div>
        </div>
    </div>
</template>

<script>
import notes from '@/components/generic/notes'

export default {
    components: { notes },
    props: {
        task: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            contacts: [],
            filterOption: '',
            taskPriorities: [],
            taskStatus: [],
            taskTypes: [],
            accounts: [],
        }
    },
    created() {
        if (this.task != null) {
            this.getTask()
            this.getLists()
        } else {
        this.getLists()
        }
    },
    methods: {
        saveTask() {
            if(this.task.id != null) {
                this.updateTask()
                console.log('updated!', this.task)
            }
            else {
                this.addTask()
            }
        },
        addTask() {
            this.$http.post('/crm/Add_Task', this.task)
            .then(() => {
                this.$emit('clicked', 'taskUpdated')
                this.$message.success('Task Added')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateTask() {
            this.$http.post('/crm/Update_Task', this.task)
            .then(() => {
                this.$emit('clicked', 'taskUpdated')
                this.$message.success('Task Updated')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/contacts/get_Contacts')
            .then((response) => {
                this.contacts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })

            this.$http.get('/lists/Get_List/taskPriorities')
            .then((response) => {
                this.taskPriorities = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })

            this.$http.get('/lists/Get_List/taskStatus')
            .then((response) => {
                this.taskStatus = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })

            this.$http.get('/lists/Get_List/taskTypes')
            .then((response) => {
                this.taskTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })

            this.$http.get('/accounts/get_Accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style scoped>
 .a-select {
     width: 100%;
 }
 .a-dp {
     width: 100%;
 }
</style>