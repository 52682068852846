<template>
    <div class="card card-bordered">
        <div class="card-inner">
            <div class="card-title">
                <h6>Currently Booked Holiday</h6>
            </div>
            <div class="card-body">
                <ul class="nk-activity" v-if="currentBooked.length">
                    <li class="nk-activity-item" v-for="cb in currentBooked" :key="cb.id">
                        <avatar style="margin-left:0px;" :src="cb.avatarURI" :size="35" :rounded="true" :backgroundColor="cb.avatarColor" :username="cb.createdByUser"/>
                        <div class="nk-activity-data">
                            <div class="label">{{ cb.name}}</div>
                            <span class="time">{{ $moment(cb.startDate).format('DD/MM/YYYY') }}</span>
                        </div>
                    </li>
                </ul>
                <div v-else>
                    No holiday currently booked
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentBooked: []
        }
    },
    created() {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>
.card-body {
    padding: 0px;
}
</style>