<template>
    <div class="col-lg-4">
      <div class="card card-bordered h-100">
            <div class="card-inner-group">
                <div class="card-inner card-inner-md">
                    <div class="card-title-group">
                        <div class="card-title">
                            <h6 class="title">My Pending Tasks</h6>
                        </div>
                        <div class="card-tools mr-n1">
                            <a-dropdown :placement="placement">
                                <div class="dropdown">
                                    <a class="dropdown-toggle btn btn-icon btn-trigger"><em class="icon ni ni-more-h"></em></a>
                                </div>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <li><a href="#"><em class="icon ni ni-setting"></em><span> Task Settings</span></a></li>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <li @click="openTask()"><em class="icon ni ni-notify"></em><span> New Task</span></li>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </div>
                </div><!-- .card-inner -->
                <div class="card-inner" v-for="task in tasks" v-bind:key="task.id">
                    <div class="nk-wg-action">
                        <div class="nk-wg-action-content">
                            <em class="icon ni ni-bulb"></em>
                            <div class="title"> {{ task.subject }}</div>
                            <p>{{ $moment(task.dueDate).format('DD/MM/YYYY') }}</p>
                        </div>
                        <a v-on:click="openTask(task)" class="btn btn-icon btn-trigger mr-n2"><em class="icon ni ni-forward-ios"></em></a>
                    </div>
                </div><!-- .card-inner -->
            </div><!-- .card-inner-group -->
        </div><!-- .card -->
        <a-drawer title="Task" :placement="drawer" :closable="true" :visible="taskDrawer" @close="closeTask" :width="600">
            <task @clicked="taskUpdated" :task="selectedTask" />
        </a-drawer>
    </div>
</template>

<script>
import task from '@/components/tasks/task.vue'

export default {
    components: { task },
    data() {
        return {
            taskDrawer: false,
            placement: 'bottomRight',
            tasks: [],
            selectedTask: null,
            drawer: 'right'
        }
    },
    created() {
        this.getTasks()
    },
    methods: {
        openTask(task) {
            this.selectedTask = task
            this.taskDrawer = true
        },
        closeTask() {
            this.getTasks()
            this.taskDrawer = false
        },
        getTasks() {
            this.$http.get('/crm/Get_Tasks/')
            .then((response) => {
                this.tasks = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        taskUpdated() {
            this.tasks = []
            this.getTasks()
        }
    }
}
</script>

<style>

</style>