<template>
    <div class="card card-bordered">
        <div class="card-inner">
            <div class="card-title">
                <h6>Holiday Stats</h6>
            </div>
            <div class="card-body">
                <div class="progress-list gy-3">
                    <div class="progress-wrap">
                        <div class="progress-text">
                            <div class="progress-label">Annual Accrual</div>
                            <div class="progress-amount">1/1.5 Days</div>
                        </div>
                        <a-progress :percent="60" :show-info="false" />
                    </div>
                    <div class="progress-wrap">
                        <div class="progress-text">
                            <div class="progress-label">Birthday</div>
                            <div class="progress-amount">1/1 Days</div>
                        </div>
                        <a-progress :percent="100" :show-info="false" />
                    </div>
                    <div class="progress-wrap">
                        <div class="progress-text">
                            <div class="progress-label">Discretionary Leave</div>
                            <div class="progress-amount">2/2 Days</div>
                        </div>
                        <a-progress :percent="100" :show-info="false" />
                    </div>
                    <div class="progress-wrap">
                        <div class="progress-text">
                            <div class="progress-label">General Holiday</div>
                            <div class="progress-amount">3/20 Days</div>
                        </div>
                        <a-progress :percent="10" :show-info="false" />
                    </div>
                    <div class="progress-wrap">
                        <div class="progress-text">
                            <div class="progress-label">Sick Leave</div>
                            <div class="progress-amount">0/0 Days</div>
                        </div>
                        <a-progress :percent="100" :show-info="false" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.card-body {
    padding: 5px;
    padding-left: 0px;
}
</style>